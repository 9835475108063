import React from "react";

export default function Intro() {
  return (
    <section className="flex-col flex items-center  mt-16 mb-16 md:mb-12">
      <h1 className="text-6xl font-bold tracking-tighter leading-tight  text-center">
        Patricia y Diego se casan
      </h1>
      <h4 className="text-center md:text-left text-lg mt-5 ">21/05/2022</h4>
    </section>
  );
}
