import React, { useState } from "react";
import Container from "../components/container";
import Intro from "../components/intro";
import { graphql, navigate } from "gatsby";
import { ExclamationCircleIcon } from "@heroicons/react/solid";
import OneQuestionForm from "../components/OneQuestionForm";

export default function Condiciones() {
  const [error, setError] = useState(false);
  const [password, setPassword] = useState("");
  const [intentos, setIntentos] = useState(0);
  const mensajes = ["¿Seguro?", "¿De verdad?", "¿Comodín del público?"];

  const condiciones = [
    {
      id: 0,
      checked: false,
      title: "Estaremos unidos hasta que la hipoteca nos separe",
      description: "Porque lo que el banco ha unido nada lo puede separar",
    },
    {
      id: 1,
      checked: false,
      title: "Cualquier decisión será consensuada democraticamente",
      description: "Aunque siempre gane lo que dice Julita",
    },
    {
      id: 2,
      checked: false,
      title:
        "La futura inauguración de la terraza debe incluir a los indecisos lucenses",
      description: "Y churrasco y cerveza, ¡por supuesto!",
    },
  ];

  const [checkedConditions, setCheckedConditions] = useState(condiciones);

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log(
    //   "TODAS MARCADAS",
    //   checkedConditions.every((c) => c.checked === true)
    // );
    // console.log("intentos", intentos);
    if (intentos >= 3 && checkedConditions.every((c) => c.checked === true)) {
      navigate("/regalo");
    } else {
      setIntentos(intentos + 1);
      const newConditions = checkedConditions.map((condition) => {
        const newCondition = { ...condition };
        newCondition.checked = false;
        return newCondition;
      });
      setCheckedConditions(newConditions);
    }
  };

  const onChangeCheckbox = (i) => () => {
    const newConditions = [...checkedConditions];
    newConditions[i].checked = !newConditions[i].checked;

    setCheckedConditions(newConditions);
  };

  console.log("checkedConditions", checkedConditions);
  return (
    <Container>
      <section className="flex-col flex items-center md:justify-between mt-8 mb-16 md:mb-12">
        <h1 className="text-6xl font-bold tracking-tighter leading-tight md:pr-8 text-center">
          El contrato.
        </h1>
        <img
          className="mt-5 rounded-2xl "
          src="/contrato.jpg"
          title="Borracho"
          alt="Borracho"
        />
        <form onSubmit={handleSubmit}>
          <fieldset className="space-y-5">
            <legend className="sr-only">Notifications</legend>

            {condiciones.map((c, i) => {
              return (
                <div className="relative flex items-start" key={c.id}>
                  <div className="flex items-center h-5">
                    <input
                      id={c.title}
                      aria-describedby="candidates-description"
                      name="candidates"
                      type="checkbox"
                      className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                      checked={checkedConditions[i].checked}
                      onChange={onChangeCheckbox(i)}
                    />
                  </div>
                  <div className="ml-3 text-sm">
                    <label
                      htmlFor={c.title}
                      className="font-medium text-gray-700"
                    >
                      {c.title}
                    </label>
                    <p id="candidates-description" className="text-gray-500">
                      {c.description}
                    </p>
                  </div>
                </div>
              );
            })}
          </fieldset>
          <p
            className={`${
              intentos === 0 && "invisible"
            } mt-1 text-sm text-red-600 py-2`}
            id="email-error"
          >
            {mensajes[(intentos - 1) % mensajes.length]}
          </p>

          <button
            type="submit"
            className=" mt-2 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Acepto las condiciones
          </button>
        </form>
      </section>
    </Container>
  );
}
